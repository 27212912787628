import { template } from '@shein/common-function'
import { promotionCenter } from 'public/src/pages/common/promotion'
import atomSer from './interfaceSer'
import { setPretreatInfo, sliceArray, includsPageKey } from './tools'
import { getSiteMallInfo } from 'public/src/pages/common/getSiteMallInfo'
import { abtservice } from 'public/src/services/abt'

const inBrowser = typeof window !== 'undefined'
const inSsr = !inBrowser

class GoodsItemInfo {
  constructor({ itemLanguage = {}, locals = {} } = {}) {
    this.itemLanguage = itemLanguage

    if (inSsr) {
      this.IS_RW = locals?.IS_RW
      this.lang = locals?.lang
      this.GB_cssRight = locals?.GB_cssRight
      this.IMG_LINK = locals?.IMG_LINK
    } else {
      this.IS_RW = gbCommonInfo?.IS_RW
      this.lang = gbCommonInfo?.lang
      this.GB_cssRight = gbCommonInfo?.GB_cssRight
      this.IMG_LINK = gbCommonInfo?.IMG_LINK
    }
    this.mallInfoForSite = []
    this.sellingPointInSearchFilter = [] // 记录分布式筛选中已经出现过的卖点数组
  }

  /**
   * 获取商品原子信息，并混入数据
   */
  async getProductInfo({
    goods = [],
    requestFields = {},
    itemConfig = {},
    pageKey = '',
    subPageKey = '',
    sceneKey = 'TWO_IN_A_ROW',
    cccParams,
    caller,
    picTopNav,
    cloudTags,
    filterWords,
    mallInfoForSite,
    onlyDealData = false,
    listAbtInfo = {},
    promotionInfoFromServer,
    NoSellingPoint = false,
    SameFilter = false,
  }) {
    // if (typeof window === 'undefined') {
    //   return
    // }
    this.fields = requestFields || {} // 这是一份完整的请求颗粒
    this.itemConfig = itemConfig || {}
    this.pageKey = pageKey
    this.subPageKey = subPageKey
    this.sceneKey = sceneKey
    this.cccParams = cccParams
    this.caller = caller
    this.picTopNav = picTopNav
    this.cloudTags = cloudTags
    this.filterWords = filterWords

    if (onlyDealData) {
      this.atomFields = {}
    } else {
      this.atomFields = { ...this.fields }
      // promotionCenter促销服务里面请求，故这里删除
      delete this.atomFields.sheinClubPromotionInfo
      delete this.atomFields.newFlashPromotion
    }

    const goodsArr = sliceArray(goods, 100)

    const atomicInfoQueue = goodsArr.map(async goods => {
      // console.info('atomicInfoQueue', this.atomFields)
      await atomSer.getAtomicInfo({
        goods, 
        fields: this.atomFields,
        itemConfig: this.itemConfig,
        pageKey, 
        subPageKey,
        sceneKey,
        caller,
        cccParams,
        cb: (goodsItem) => {
          this.dealExt({ goodsItem })
        }
      })
    })

    let requestQueue = []
    // 站点mall信息
    if (!mallInfoForSite && typeof window !== 'undefined') {
      requestQueue.push(getSiteMallInfo())
    }
    // 原子接口
    if (Object.keys(this.atomFields).length) {
      requestQueue = [...requestQueue, ...atomicInfoQueue]
    }

    // abt
    this.abtInfo = {}
    const newPosKeys = []
    if (includsPageKey({ pageKey: subPageKey || pageKey }) && requestFields.cccTspBadges) {
      newPosKeys.push('NewPosition')
    }

    if (onlyDealData) {
      this.abtInfo = listAbtInfo
    } else if (newPosKeys.join()) {
      requestQueue.push(new Promise(resolve => {
        // eslint-disable-next-line @shein-aidc/abt/abt
        abtservice
          .getUserAbtResult({ newPosKeys })
          .then(abtInfo => {
            this.abtInfo = abtInfo
            resolve()
          })
      }))
    }

    let mallInfo = {}
    if (requestQueue.length) {
      [ mallInfo ] = await Promise.all(requestQueue)
    }

    // 站点mall，默认取传入的，否则取请求请回来
    this.mallInfoForSite = mallInfoForSite?.length 
      ? mallInfoForSite 
      : mallInfo?.mallInfoForSite?.length
        ? mallInfo.mallInfoForSite
        : []

    // 促销信息预处理
    const associatedPromotionInfoQueue = goodsArr.map(async goods => {
      await this.getAssociatedPromotionInfo({ goods, caller: this.caller, onlyDealData, promotionInfoFromServer })
    })

    await Promise.all(associatedPromotionInfoQueue)

    goods.forEach((item) => {
      setPretreatInfo({ goodsItem: item, field: 'getInfoLoading', value: true })

      this.dealData(item, picTopNav, cloudTags, filterWords, NoSellingPoint, SameFilter)

      setPretreatInfo({ goodsItem: item, field: 'getInfoLoading', value: false })
    })
    // 处理完后重置 sellingPointInSearchFilter 为空数组
    if (SameFilter) {
      this.sellingPointInSearchFilter = []
    }

    return Promise.resolve(goods)
  }
  /**
   * 获取商品项中多颜色商品的信息 ex: 价格，库存，上下架等
  */
  async getRelateColorProductInfo ({ goods = [] }, colorAtomFields, NoSellingPoint = false, atomicParams = {}) {
    if (typeof window === 'undefined') return
    
    // 提取多色块
    let relatedColor = []
    goods.forEach(goodsInfo => {
      if (goodsInfo.relatedColor?.length) {
        relatedColor = relatedColor.concat(goodsInfo.relatedColor)
      }
    })
    if (!relatedColor.length) return

    const relatedColorArr = sliceArray(relatedColor, 100)

    let atomFields = {
      ...this.fields,
      ...(colorAtomFields || {}),
      baseInfo: true,
    }
    // promotionCenter促销服务里面请求，故这里删除
    delete atomFields.sheinClubPromotionInfo
    delete atomFields.newFlashPromotion

    // 一些场景 没有取主商品的原子数据（getProductInfo）， 直接取色块的原子数据，需要自己传入原子需要的参数
    if (Object.keys(atomicParams).length) {
      const { fields, scene, cccParams, caller, itemConfig } = atomicParams || {}
      const { pageKey, sceneKey, subPageKey } = scene || {}
      atomFields = {
        ...atomFields,
        ...fields
      }
      this.pageKey = pageKey
      this.sceneKey = sceneKey
      this.subPageKey = subPageKey
      this.caller = caller
      this.cccParams = cccParams
      this.itemConfig = itemConfig
    }

    // 原子接口
    const atomicInfoQueue = relatedColorArr.map(async relatedColor => {
      await atomSer.getAtomicInfo({
        goods: relatedColor, 
        fields: atomFields,
        itemConfig: this.itemConfig,
        pageKey: this.pageKey, 
        subPageKey: this.subPageKey,
        sceneKey: this.sceneKey,
        caller: this.caller,
        cccParams: this.cccParams,
        cb: (goodsItem) => {
          this.dealExt({ goodsItem })
        }
      })
    })

    await Promise.all(atomicInfoQueue)

    // 促销信息预处理
    const associatedPromotionInfoQueue = relatedColorArr.map(async relatedColor => {
      await this.getAssociatedPromotionInfo({ goods: relatedColor, caller: this.caller, colorAtomFields })
    })

    await Promise.all(associatedPromotionInfoQueue)

    relatedColor.forEach(async (relatedColorInfo) => {
      setPretreatInfo({ goodsItem: relatedColorInfo, field: 'getInfoLoading', value: true })
      
      this.dealData(relatedColorInfo, this.picTopNav, this.cloudTags, this.filterWords, NoSellingPoint )
  
      setPretreatInfo({ goodsItem: relatedColorInfo, field: 'getInfoLoading', value: false })
    })
  }
  /** 
   * 处理数据 
   */
  dealData (goodsItem, picTopNav, cloudTags, filterWords, NoSellingPoint = false, SameFilter = false) {
    // 折扣角标初始化
    this.dealUnitDiscount({ goodsItem })
    // 格式化badge
    this.dealBadge({ goodsItem })
    // 品牌系列，埋点数据
    this.dealSeriesBrandAnalysis({ goodsItem })
    // 系列 品牌logo
    this.dealsSeriesOrBrandLogo({ goodsItem })
    // 大码
    this.dealPlusSize({ goodsItem })
    // 售罄
    this.dealSaleout({ goodsItem })
    // 卖点
    this.dealSellingPoints({ goodsItem })
    // 搜索分布式筛选
    this.dealSearchFilter({ goodsItem, picTopNav, cloudTags, filterWords, NoSellingPoint, SameFilter })
    // 本地发货标签
    this.dealLocalShipping({ goodsItem })
    // 展示个位数剩余库存,  0 < 库存 < 10
    this.dealLeftStock({ goodsItem })
    // 降价标识
    this.dealReducePrice({ goodsItem })
    // 销售属性
    this.dealSaleAttr({ goodsItem })
    // 减价（price cut）
    this.dealPriceCut({ goodsItem })
    // 保留款
    this.dealTspLabels({ goodsItem })
    // 橱窗配置数据
    this.dealDisplayWindowInfo({ goodsItem })
    // ...添加最近90天数据
    // ...添加评论星级
  }
  /**
   * 获取促销关联信息
  */
  async getAssociatedPromotionInfo ({ goods = [], caller, onlyDealData = false, promotionInfoFromServer, colorAtomFields } = {}) {
    const { sheinClubPromotionInfo, newFlashPromotion } = Object.keys(this.fields || {})?.length ? this.fields : colorAtomFields || {}
    const { hideS3VipPrice } = this.itemConfig || {}

    await promotionCenter.assignItems({ 
      items: goods, 
      isGetLable: true, 
      isGetVipPrice: !hideS3VipPrice,
      isGetSheinClubPrice: sheinClubPromotionInfo,
      isGetNewFlashPromotion: newFlashPromotion, 
      isAppPromotion: true, 
      caller,
      onlyDealData,
      promotionInfoFromServer,
    })

    goods.forEach(item => {
      const { promotionModule, newFlashPromotion } = item
      const { price, id, typeId } = newFlashPromotion || {} // 命中新型闪购价格
      promotionModule?.rawModule?.forEach(promo => {
        const { flash_type } = promo
        switch(Number(promo?.typeId)) {
          case 24: // 限购促销
            if (promo.ruleType != 'no_discount') {
              setPretreatInfo({ goodsItem: item, field: 'label', value: 'Limited Stock' })
            }
            break
          case 12: // vip 价格
            item['exclusivePromotionPrice'] = item.vipPrice
            break
          case 29: // 付费会员
            item['sheinClubPromotionInfo'] = item.sheinClubPromotionInfo
            break
          // case 3: // 限时活动, 展示icon，展示折上折
          //   setPretreatInfo({ goodsItem: item, field: 'limitedoff', value: true })
          //   // setPretreatInfo({ goodsItem: item, field: 'limitedoffIcon', value: 'iconfont icon-limitedoff-new' })
          //   break
          case 10 || 11: // 10 常规闪购, 11 直播闪购
            if (flash_type == 2 || flash_type == 3) { // 新型闪购 2-新人 3-专属
              if (!newFlashPromotion) return
              
              let flashTypeText = this.itemLanguage.SHEIN_KEY_PWA_21928
              if (flash_type == 3) flashTypeText = this.itemLanguage.SHEIN_KEY_PWA_21932
              setPretreatInfo({ goodsItem: item, field: 'newFlashPromotion', value: { id, typeId, flash_type, price, flashTypeText } })
            } else if (flash_type == 4) {
              item['flashZonePromotion'] = { price }
            } else { // 常规、直播闪购
              setPretreatInfo({
                goodsItem: item,
                field: 'flashsale',
                value: {
                  info: promo,
                  saleProcess: `${promo.soldNum / promo.buyLimit * 100}%`,
                  soldNum: `${template(promo.soldNum, this.itemLanguage.SHEIN_KEY_PWA_15054)}`
                }
              })
            }
            break
        }
      })
      
      setPretreatInfo({ goodsItem: item, field: 'mixPromotionInfo', value: promotionModule.rawModule })
      setPretreatInfo({ goodsItem: item, field: 'promotionReady', value: true })
      setPretreatInfo({ goodsItem: item, field: 'newFlashPromotionReady', value: true })
    })
  }
  /** ext上报信息处理 */
  dealExt ({ goodsItem = null } = {}) {
    if (!goodsItem) return

    const ext = goodsItem.ext
    const d_ext = goodsItem.dynamic_ext
    if (ext) {
      ext.other_ext_mark = Object
        .keys(ext)
        .reduce((curr, next) => {
          if (next != 'rec_mark' && next != 'extra_mark') {
            curr.push(`${next}_${ext[next]}`)
          }
          return curr
        }, [])
        .join('|')
    }
    if (d_ext) {
      d_ext.other_d_ext_mark = Object
        .keys(d_ext)
        .map(_ => `${_}_${d_ext[_]}`)
        .join('|')
    }
  }
  /**
   * 格式化折扣角标
  */
  dealUnitDiscount ({ goodsItem = null } = {}) {
    if (!goodsItem || ((!goodsItem.unit_discount || Number(goodsItem.unit_discount) === 0) && !goodsItem.newFlashPromotion)) return
    let discountInfo = {}

    // 优先取新型闪购折扣
    const newFlashDiscount = goodsItem.newFlashPromotion?.discount_percent
    const discount = newFlashDiscount || goodsItem.unit_discount // 新型闪购折扣率优先于原折扣率
    discountInfo.unitDiscountString = discount ? `${this.lang !== 'il' ? '-' : ''}${discount}%` : ''
    discountInfo.newFlashDiscount = newFlashDiscount

    setPretreatInfo({ 
      goodsItem, 
      field: 'discountInfo', 
      value: { 
        ...discountInfo, 
        show: !!discountInfo.unitDiscountString 
      }
    })
  }

  /** 
   * 格式化badge, 目前支持的位置，图片左下角，图片左上角和商品下方，
  */
  dealBadge ({ goodsItem = null } = {}) {
    if (!goodsItem || !goodsItem.badges || !this.itemConfig.showBadge) {
      setPretreatInfo({ goodsItem, field: 'badgesReady', value: true })
      return
    }
    const { P_UPPER_LEFT, P_BOTTOM_LEFT, BELOW_PRICE, pageKey, sceneKey } = goodsItem.badges

    Object.assign(goodsItem, { pageKey, sceneKey }) // 切色块时，获取badge时需要

    const isLowInStock = (id) => id == 70007016
    const handlePretreatInfo = (field, positionBadgeData) => {
      const { name, 'name-en': nameEn, backgroundColor, fontColor, id } = positionBadgeData[0]
      const formatNameEn = nameEn?.split(' ')?.join('_')?.toLowerCase() || ''
      const badgeInfo = { text: name, fontColor, backgroundColor, code: `show_label_${formatNameEn}`, isLowInStock: isLowInStock(id) }
      setPretreatInfo({ goodsItem, field, value: badgeInfo })
    }
    // 图片左上角标
    if (P_UPPER_LEFT) {
      let position = 'imgUpperLeft'
      if (this.abtInfo?.NewPosition?.p?.NewPosition == 'FrontTitle') {
        position = 'frontTitle'
      }
      handlePretreatInfo(position, P_UPPER_LEFT)
    }

    // 图片左下角标
    if (P_BOTTOM_LEFT) {
      handlePretreatInfo('imgBottomLeft', P_BOTTOM_LEFT)
    }

    // 商品底部
    if (BELOW_PRICE) {
      handlePretreatInfo('goodsBottom', BELOW_PRICE)
    }
    
    setPretreatInfo({ goodsItem, field: 'badgesReady', value: true })
  }
  /**
   * 品牌系列，埋点数据
  */
  dealSeriesBrandAnalysis ({ goodsItem = null, IS_RW = undefined } = {}) {
    if (!goodsItem && !this.itemConfig.showSeriesBrand) return
    if (typeof window !== 'undefined') {
      IS_RW = this.IS_RW
    }

    const { image_url: seriesLogo, name: seriesName } = goodsItem.series || goodsItem.series_badge || {}
    const { name: brandName, brand_logo: brandLogo } = goodsItem.brand || goodsItem.brand_badge_new || {}

    const seriesBadge = !IS_RW && seriesName ? `${seriesName} ` : ''

    // 埋点数据,系列品牌上报, 系列优先于品牌
    let seriesOrBrand = ''
    if (seriesBadge && seriesLogo) {
      seriesOrBrand = `series_${seriesBadge.toLowerCase()}`
    } else {
      if (!IS_RW) {
        if (brandName && brandLogo) seriesOrBrand = `brand_${brandName.toLowerCase()}`
      } else {
        if (goodsItem.isAlone == 1) {
          seriesOrBrand = 'series_romweoriginals'
        } else if (brandName && brandLogo) {
          seriesOrBrand = `brand_${brandName.toLowerCase()}`
        }
      }
    }

    setPretreatInfo({ goodsItem, field: 'seriesOrBrandAnalysis', value: seriesOrBrand })
  }
  /**
   * 系列 or 品牌logo展示
  */
  dealsSeriesOrBrandLogo ({ goodsItem = null } = {}) {
    if (!goodsItem && !this.itemConfig.showSeriesBrand) return

    const series = goodsItem.series || goodsItem.series_badge || {}
    const brand = goodsItem.brand || goodsItem.brand_badge_new || {}

    if (!this.IS_RW) {
      // 系列
      const seriesLogo = this.GB_cssRight ? series.corner_img_right : series.corner_img_left
      if (seriesLogo) {
        setPretreatInfo({ goodsItem, field: 'seriesLogo', value: seriesLogo })
        setPretreatInfo({ goodsItem, field: 'seriesLogoName', value: series.name || '' })
      }

      // 品牌
      const brandLogo = brand.corner_img_left
      if (brandLogo) {
        setPretreatInfo({ goodsItem, field: 'brandLogo', value: brandLogo })
        setPretreatInfo({ goodsItem, field: 'brandLogoName', value: brand.name || '' })
      }
    } else {
      if (series.image_url) {
        setPretreatInfo({ goodsItem, field: 'seriesOrBrandLogoBottom', value: series.image_url })
      } else if (goodsItem.isAlone && Number(goodsItem.isAlone) === 1) {
        setPretreatInfo({ goodsItem, field: 'romweoriginals', value: this.itemLanguage.SHEIN_KEY_PWA_16971 })
      }
    }
  }
  /** 大码 */
  dealPlusSize ({ goodsItem = null }) {
    if (!goodsItem || !this.itemConfig.showPlusSize) return

    if (goodsItem.is_show_plus_size && Number(goodsItem.is_show_plus_size) !== 0) {
      setPretreatInfo({ goodsItem, field: 'plusSize', value: this.itemLanguage.SHEIN_KEY_PWA_16807 })
      if (goodsItem.pretreatInfo?.seriesLogo || this.IS_RW) return

      setPretreatInfo({ goodsItem, field: 'bigImgOut', value: 1 }) // set大码兜底图
      const urlKey = this.GB_cssRight ? 'curve-plus-r' : 'curve-plus'
      const imgLink = {
        'curve-plus': '/pwa_dist/images/plus_size/curve-plus-b50bac3f89.png',
        'curve-plus-r': '/pwa_dist/images/plus_size/curve-plus-r-52ab3e3dc6.png',
      }
      setPretreatInfo({ goodsItem, field: 'seriesLogo', value: `${(this.IMG_LINK || imgLink)?.[urlKey]}` })
    }
  }
  /** 售罄 */
  dealSaleout ({ goodsItem = null }) {
    if (!goodsItem) return

    if (Number(goodsItem.is_on_sale) === 0 || Number(goodsItem.stock) === 0) {
      setPretreatInfo({
        goodsItem,
        field: 'saleout',
        value: {
          show: true,
          message: this.itemLanguage.SHEIN_KEY_PWA_14987
        }
      })
    }
  }
  // 卖点
  dealSellingPoints ({ goodsItem = null }) {
    if (!goodsItem?.tag_result || !this.itemConfig.showSellingPoint) {
      return setPretreatInfo({ goodsItem, field: 'sellingPointsReady', value: true })
    }

    let sellingPointsBySort = []
    for(let tag of goodsItem.tag_result) {
      for (let tagValue of tag.tags) {
        tagValue.tag_id = tag.tag_id
        sellingPointsBySort.push(tagValue)
      }
    }
    sellingPointsBySort = sellingPointsBySort.sort((a, b) => a.tag_sort - b.tag_sort)
    
    setPretreatInfo({ goodsItem, field: 'sellingPoints', value: sellingPointsBySort })
    setPretreatInfo({ goodsItem, field: 'sellingPointsReady', value: true })
  }
  // 分布式筛选
  dealSearchFilter ({ goodsItem = null, picTopNav, cloudTags, filterWords, NoSellingPoint = false, SameFilter = false }) {
    if ((!goodsItem?.attrs?.length && !goodsItem.pretreatInfo?.sellingPoints?.length) || !this.itemConfig.showSearchFilters) {
      return setPretreatInfo({ goodsItem, field: 'searchFiltersReady', value: true })
    }

    let sellingPoints = []
    if (!NoSellingPoint && this.itemConfig.showSellingPoint) {
      sellingPoints = goodsItem.pretreatInfo.sellingPoints || [] // 卖点
      // 字段统一格式化一下
      sellingPoints = sellingPoints
        // 过滤标签云和已点击筛选词重复的项
        .filter(sellingPoint => 
          !cloudTags?.find(cloudTag => cloudTag.tag_id == sellingPoint.tag_val_id) && 
          !filterWords?.find(word => word == sellingPoint.tag_val_name_lang)
        ) 
        .map(item => {
          const { tag_id, tag_val_id, tag_val_name_lang } = item
          item.id = `${tag_id}_${tag_val_id}` || ''
          item.filterId = tag_val_id || ''
          item.filterWord = tag_val_name_lang || ''
          item.dataSource = 1

          return item
        })
    }

    // 过滤分布式筛选中已经出现过的热点
    if (SameFilter) {
      if (sellingPoints.length && this.sellingPointInSearchFilter.length) {
        sellingPoints = sellingPoints.filter(sp => !this.sellingPointInSearchFilter.find(spisf => spisf.id === sp.id))
      }
      this.sellingPointInSearchFilter = [...this.sellingPointInSearchFilter, ...sellingPoints]
    }
    
    let coreAttrs = goodsItem.attrs || [] // 核心属性
    const navs = picTopNav[0]?.navs || [] // 图文导航
    coreAttrs = coreAttrs
      // 过滤图文导航和已点击筛选词重复的项
      .filter(attr => 
        !navs.find(nav => nav.nav_id == `${attr.attr_id}_${attr.attr_value_id}`) && 
        !filterWords?.find(word => word == attr.attr_values)
      )
      .map(item => {
        // 字段统一格式化一下
        const { attr_id, attr_value_id, attr_values } = item
        item.id = `${attr_id}_${attr_value_id}`
        item.filterId = attr_value_id || ''
        item.filterWord = attr_values || ''
        item.dataSource = 2

        return item
      })

    setPretreatInfo({ goodsItem, field: 'searchFilters', value: sellingPoints.concat(coreAttrs) })
    setPretreatInfo({ goodsItem, field: 'searchFiltersReady', value: true })
  }
  // 本地发货标签
  dealLocalShipping ({ goodsItem = null }) {
    if (!goodsItem || !this.mallInfoForSite?.length) {
      return setPretreatInfo({ goodsItem, field: 'localMallReady', value: true })
    }

    let mallTagsInfo = {}

    const { language_mall_name, mall_tags } = this.mallInfoForSite.find(mallInfo => mallInfo.mall_code == goodsItem.mall_code) || {}

    if (!mall_tags) {
      setPretreatInfo({ goodsItem, field: 'mallTagsInfo', value: mallTagsInfo })
      setPretreatInfo({ goodsItem, field: 'localMallReady', value: true })
      return
    }

    const isOverSeaMall = mall_tags.includes('4')
    const isLocalMall = mall_tags.includes('5')

    Object.assign(mallTagsInfo, { localMall: isLocalMall && !isOverSeaMall, mall_tags, language_mall_name })
    
    setPretreatInfo({ goodsItem, field: 'mallTagsInfo', value: mallTagsInfo })
    setPretreatInfo({ goodsItem, field: 'localMallReady', value: true })
  }

  // 剩余库存
  dealLeftStock ({ goodsItem = null }) {
    if (!goodsItem || !this.itemConfig.showLeftStock) {
      setPretreatInfo({ goodsItem, field: 'stockLeftReady', value: true })
      return
    }

    const { stock, attrValueId, sizeStock, sku_code } = goodsItem
    const _stock = sku_code || +attrValueId ? sizeStock : stock
    if (+_stock > 0 && +_stock < 10) {
      setPretreatInfo({ goodsItem, field: 'stockLeft', value: template(_stock, this.itemLanguage.SHEIN_KEY_PWA_16814) })
    }

    setPretreatInfo({ goodsItem, field: 'stockLeftReady', value: true })
  }

  // 降价标识
  dealReducePrice ({ goodsItem = null }) {
    if (!goodsItem || !this.itemConfig.showReducePrice) return

    const { amountWithSymbol, usdAmount } = goodsItem.reducePrice || {}
    setPretreatInfo({ 
      goodsItem, 
      field: 'reducePrice', 
      value: {
        label: amountWithSymbol && template(amountWithSymbol, this.itemLanguage.SHEIN_KEY_PWA_16323),
        usdAmount
      }
    })
  }

  // 销售属性
  dealSaleAttr ({ goodsItem = null }) {
    if (!goodsItem || !this.itemConfig.showSaleAttr) return

    const attrList = goodsItem.sku_attr_list || []
    const attrNames = attrList.reduce((acc, cur) => {
      cur.is_main === 0 && (acc.push(cur.attr_value_multi_name))

      return acc
    }, []).join('/')

    setPretreatInfo({ 
      goodsItem, 
      field: 'saleAttr', 
      value: { 
        label: attrNames,
        analysis: attrNames ? `attribute_show_${attrNames}` : 'attribute_null'
      } 
    })
  }

  // 减价（price cut）
  dealPriceCut ({ goodsItem = null }) {
    if (!goodsItem || !this.itemConfig.showPriceCut) {
      setPretreatInfo({ goodsItem, field: 'priceCutReady', value: true })
      return
    }

    const { expired_salePrice, salePrice } = goodsItem // expired_salePrice过期价格, salePrice当前价格

    const salePriceDelta = Number(expired_salePrice?.usdAmount || 0) - Number(salePrice?.usdAmount || 0)

    if (salePriceDelta > 0) {
      setPretreatInfo({ goodsItem, field: 'priceCut', value: salePriceDelta })
    }

    setPretreatInfo({ goodsItem, field: 'priceCutReady', value: true })
  }

  // 保留款,跟价款
  dealTspLabels({ goodsItem }) {
    if (!goodsItem) return

    // const saleout = goodsItem.pretreatInfo?.saleout?.show

    // if (this.itemConfig.showBestDealLabel) {
    //   setPretreatInfo({ 
    //     goodsItem, 
    //     field: 'showBestDeal', 
    //     value: !!(!this.IS_RW && !saleout && goodsItem.realTimeTspLabels?.['3598'])
    //   })
    // }
    // if (this.itemConfig.showFollowLabel) {
    //   setPretreatInfo({ 
    //     goodsItem,
    //     field: 'followLabelVisible', 
    //     value: !!(!this.IS_RW && !saleout && goodsItem.realTimeTspLabels?.['3658'])
    //   })
    // }
  }

  // 橱窗数据
  dealDisplayWindowInfo ({ goodsItem }) {
    if (!goodsItem) return

    const mobileBroadwiseViewValue = {}
    const mobileVerticalViewValue = {}
    const { mobileVerticalView, mobileBroadwiseView } = goodsItem || {}

    const setValue = ({ key, broadwiseLabel, verticalLabel, broadWiseAna, verticalAna }) => {
      if (broadwiseLabel) {
        mobileBroadwiseViewValue[key] = {
          ...broadwiseLabel,
          ana: broadWiseAna
        }
      }
      if (verticalLabel) {
        mobileVerticalViewValue[key] = {
          ...verticalLabel,
          ana: verticalAna
        }
      }
    }
    
    // 用户评论关键词
    // 用户评论关键词与用户行为标签互斥
    let showUserCommentKeywordsLabel = this.itemConfig.showUserCommentKeywordsLabel
    let userCommentKeywordsLabelInfo = goodsItem.userCommentKeywordsLabelInfo ?? {}
    let userCommentKeywordsLabelInfolabelLangEn = userCommentKeywordsLabelInfo?.labelLangEn ?? ''

    if (showUserCommentKeywordsLabel && userCommentKeywordsLabelInfolabelLangEn) {
      const getAna = `show_review_label_${userCommentKeywordsLabelInfolabelLangEn}`

      setValue({
        key: 'userCommentKeywordsLabel',
        broadwiseLabel: userCommentKeywordsLabelInfo,
        verticalLabel: userCommentKeywordsLabelInfo,
        broadWiseAna: getAna, 
        verticalAna: getAna
      })
    } else if (this.itemConfig.showUserBehaviorLabel) {
      // 用户行为标签
      const getAna = (labelInfo) => ['show_behavior_label', labelInfo.labelId, labelInfo.labelLangEn?.split(' ')?.join('_')].join('_')

      const broadwiseUserBehaviorLabel = mobileBroadwiseView?.userBehaviorLabels?.[0]
      const verticalViewUserBehaviorLabel = mobileVerticalView?.userBehaviorLabels?.[0]

      setValue({
        key: 'userBehaviorLabel',
        broadwiseLabel: broadwiseUserBehaviorLabel,
        verticalLabel: verticalViewUserBehaviorLabel,
        broadWiseAna: broadwiseUserBehaviorLabel && getAna(broadwiseUserBehaviorLabel), 
        verticalAna: verticalViewUserBehaviorLabel && getAna(verticalViewUserBehaviorLabel)
      })
    } else if (this.itemConfig.showUserPreferencesLabel) {
      /// 偏好标签
      const userPreferencesLabel = mobileBroadwiseView?.userPreferencesLabels?.[0]
      const ana = {
        action_item_add_cart: 'show_label_addcar',
        action_item_collect: 'show_label_inwishlist',
        action_item_click: 'show_label_viewrecent'
      }[userPreferencesLabel?.labelId] || ''

      setValue({
        key: 'userPreferencesLabels',
        broadwiseLabel: userPreferencesLabel,
        verticalLabel: userPreferencesLabel,
        broadWiseAna: ana,
        verticalAna: ana
      })
    }

    // 腰带
    if (this.itemConfig.showBeltLabel && !goodsItem.pretreatInfo?.showBestDeal) {
      const broadwiseBeltLabel = mobileBroadwiseView?.beltLabels
      const verticalViewBeltLabel = mobileVerticalView?.beltLabels
      setValue({
        key: 'beltLabel',
        broadwiseLabel: broadwiseBeltLabel,
        verticalLabel: verticalViewBeltLabel,
        broadWiseAna: `belt_CCC${broadwiseBeltLabel?.labelId}`,
        verticalAna: `belt_CCC${verticalViewBeltLabel?.labelId}`,
      })
    }

    // 榜单
    if (this.itemConfig.showRankingList) {
      const broadwiseRankingList = mobileBroadwiseView?.rankingList
      const verticalViewRankingList = mobileVerticalView?.rankingList
      setValue({
        key: 'rankingList',
        broadwiseLabel: broadwiseRankingList,
        verticalLabel: verticalViewRankingList,
        broadWiseAna: `show_rank_type_${broadwiseRankingList?.rankingType}`,
        verticalAna: `show_rank_type_${verticalViewRankingList?.rankingType}`,
      })
    }

    // 单图
    if (Object.keys(mobileBroadwiseViewValue)?.length) {
      setPretreatInfo({ 
        goodsItem, 
        field: 'mobileBroadwiseView', 
        value: mobileBroadwiseViewValue
      })
    }
    // 双图
    if (Object.keys(mobileVerticalViewValue)?.length) {
      setPretreatInfo({ 
        goodsItem, 
        field: 'mobileVerticalView', 
        value: mobileVerticalViewValue 
      })
    }

    setPretreatInfo({ goodsItem, field: 'userBehaviorLabelReady', value: true }) // 用户行为标签数据已经准备完毕
    setPretreatInfo({ goodsItem, field: 'rankingListReady', value: true }) // 榜单数据已经准备完毕
  }
}

export {
  GoodsItemInfo
}
